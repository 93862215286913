import React from "react";
import { withRouter } from "react-router";
import axios from "axios";
import swal from "sweetalert";
import { MatchingLogicPopUp } from "./MatchingLogicPopUp";

const config = [
  "supportedDevices",
  "activityType",
  "locales",
  "monitoringTriggerTypes",
  "badges",
  "skillTier",
  "skillTypes",
  "workflowTypes",
];

const defaultMapping = [
  {
    cardName: "badges",
    options: [{ radioName: "Subset" }, { radioName: "Exact" }],
  },
  {
    cardName: "skillTypes",
    options: [{ radioName: "Subset" }, { radioName: "Exact" }],
  },
  {
    cardName: "devices",
    options: [{ radioName: "Subset" }, { radioName: "Exact" }],
  },
]

class AddConfigs extends React.Component {
  constructor(props) {
    super();

    this.state = {
      talentConfig: {},
      selectedCheckboxes: {},
      configName: "",
      configDesc: "",
      requestBody: {},
      apiResponse: {},
      addModalShowMatch: false,
      configurationMapping: {},
      data1: [
        {
          cardName: "badges",
          options: [{ radioName: "Subset" }, { radioName: "Exact" }],
        },
        {
          cardName: "skillTypes",
          options: [{ radioName: "Subset" }, { radioName: "Exact" }],
        },
        {
          cardName: "devices",
          options: [{ radioName: "Subset" }, { radioName: "Exact" }],
        },
      ],
    };
  }

  componentDidMount() {
    axios
      .get(
        `https://aquiline-beta.aka.corp.amazon.com/getMasterTalentConfiguration`
      )
      .then((res) => {
        this.setState({
          talentConfig: res.data,
        });
      });
  }

  handleChangeName(event) {
    let nameObj = this.state.configName;
    nameObj = event.target.value;
    this.setState({ configName: nameObj });
  }

  handleChangeDesc(event) {
    let nameObj = this.state.description;
    nameObj = event.target.value;
    this.setState({ configDesc: nameObj });
  }

  handleChange(event, itemKey, childItemKey) {
    // let configName =
    let selectedCheckboxesObj = this.state.selectedCheckboxes;
    if (!this.state.selectedCheckboxes[itemKey]) {
      selectedCheckboxesObj[itemKey] = {};
    }
    selectedCheckboxesObj[itemKey][childItemKey] = event.target.checked;
    this.setState({ selectedCheckboxes: selectedCheckboxesObj });
  }

  getFormatedMapping = (data) => {
    let arrayObj = [];
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < 2; j++) {
        if (data[i].options[j].selected) {
          if (data[i].options[j].radioName === "Exact") {
            arrayObj.push({
              [data[i].cardName]: 1,
            });
          } else {
            arrayObj.push({
              [data[i].cardName]: 0,
            });
          }
        }
      }
    }

    var object = Object.assign({}, ...arrayObj);

    console.log(object);
    return object;
  };

  onSubmitRequest = () => {
    console.log("Data inside Submit", this.state.data1)
    const mapping = this.getFormatedMapping(this.state.data1);
    console.log("Data after convertion", mapping)
    let requestBody = {};
    Object.keys(this.state.selectedCheckboxes).forEach((item) => {
      requestBody[item] = Object.keys(
        this.state.selectedCheckboxes[item]
      ).filter(
        (selectedItem) => this.state.selectedCheckboxes[item][selectedItem]
      );
    });
    console.log(requestBody);
    console.log(this.state.configName);
    console.log(this.state.configDesc);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        configId: this.state.configName,
        configuration: requestBody,
        description: this.state.configDesc,
        isEnabled: 1,
        configurationMapping: mapping
      }),
    };
    console.log(requestOptions);
    console.log(this.state.configName);
    console.log(requestBody);
    fetch("https://aquiline-beta.aka.corp.amazon.com/addTC", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        return data;
      })
      .then((data) => this.setState({ apiResponse: data }));
    swal({
      text: "You have successfully added Configuration",
      type: "success",
    }).then((okay) => {
      if (okay) {
        window.location.href = "http://localhost:3000/TalentConfigurations";
      }
    });
  };

  refreshPage() {
    window.location.reload(false);
  }

  onInputChange = ({ target }) => {
    const { data1 } = this.state;
    const nexState = data1.map((card) => {
      if (card.cardName !== target.name) return card;
      return {
        ...card,
        options: card.options.map((opt) => {
          const checked = opt.radioName === target.value;
          return {
            ...opt,
            selected: checked,
          };
        }),
      };
    });
    this.setState({ data1: nexState });
    console.log("Data1 in ONChange", this.state.data1);
  };

  onSubmit = (e) => {
    e.preventDefault();
    // this.onMappingChange(this.state.data1);
    console.log("OnSubmit Modified Data", this.state.data1);
    swal({
      title: "Are you sure?",
      text: "This will make changes in Matching Configuration",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willClose) => {
      if (willClose) {
        swal("Closing the Mapping Configuration ", {
          icon: "success",
        });
        this.setState({ addModalShowMatch: false });
      } else {
        swal("Discard save!");
        this.setState({ addModalShowMatch: false });
      }
    });
  };

  render() {
    const { talentConfig, addModalShowMatch, data1 } = this.state;
    let addModalClose = () =>

      this.setState({ addModalShowMatch: false, data1: defaultMapping });
    return (
      <div className="AddConfigs">
        <div className="name">  
          <h3 className="headerInline">Add New Configuration</h3>
          <div className="button headerInline headerMargin textSizeHeader">
            <button className="link-align disable-styling button-styling" onClick={this.refreshPage}>Discard</button>
            <button className="link-align enable-styling button-styling" onClick={this.onSubmitRequest} >Save</button>
          </div>
        </div>
        <br />
        <div>
          <button
            className="link-align addNewStyling button-styling textSizeHeader headerMargin"
            onClick={() => this.setState({ addModalShowMatch: true })}
          > Mapping Logic
        </button>
          <MatchingLogicPopUp
            data1={data1}
            show={this.state.addModalShowMatch}
            onHide={addModalClose}
            mappingAdded={this.onMappingChange}
            onSubmit={this.onSubmit}
            onInputChange={this.onInputChange}
          />
        </div>
        <form>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text textSize addNewStyling">Config Name:</span>
            </div>
            <textarea
              onChange={(event) => this.handleChangeName(event)}
              class="form-control textSize"
              id="exampleFormControlTextarea1"
              rows="2"
              placeholder="Enter Config Name"
              aria-label="With textarea"
            ></textarea>
          </div>

          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text textSize addNewStyling">Description:</span>
            </div>
            <textarea
              onChange={(event) => this.handleChangeDesc(event)}
              className="form-control textSize"
              id="exampleFormControlTextarea1"
              rows="2"
              placeholder="Enter Description"
              aria-label="With textarea"
            ></textarea>
          </div>
        </form>
        <div className="AddConfigs">
          {talentConfig.configuration &&
            config.map((item, index) => {
              return (
                <>
                  <h8 className="addNewStyling textSizeSemiHeader upperCaseFont">{item}</h8>
                  <div className="main-wrapper" key={index}>
                    {talentConfig.configuration[item].map(
                      (childItem, childIndex) => {
                        return (
                          <div className="sections textSize upperCaseFont wrapContent" key={childIndex}>
                            <input
                              type="checkbox"
                              className="checkboxSpacing"
                              onChange={(event) =>
                                this.handleChange(event, item, childItem)
                              }
                              checked={
                                this.state.selectedCheckboxes[item] &&
                                this.state.selectedCheckboxes[item][childItem]
                              }
                              id={childItem}
                              value={childItem}
                            ></input>
                            <label className="checkboxSpacing" htmlFor={childItem}>{childItem}</label>
                          </div>
                        );
                      }
                    )}
                  </div>
                </>
              );
            })}
        </div>
        {/* <button type="submit">Submit</button> */}
      </div>
    );
  }
}
export default withRouter(AddConfigs);
