import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";

const TalentConfiguration = () => {
  const [talentConfigurations, setTalentConfigurations] = useState([]);

  useEffect(() => {
    loadTalentConfigurations();
  }, []);

  const loadTalentConfigurations = async () => {
    const result = await axios.get(
      "https://aquiline-beta.aka.corp.amazon.com/getActiveTalentConfigurationDashboardDetails"
    );
    setTalentConfigurations(result.data);
  };

  const [search, setSearch] = useState("");
  const [filteredConfigs, setFilteredConfigs] = useState([]);

  useEffect(() => {
    setFilteredConfigs(
      talentConfigurations.filter((config) =>
        config.talentConfigurationName
          .toLowerCase()
          .includes(search.toLowerCase())
      )
    );
  }, [search, talentConfigurations]);

  return (
    <div className="container">
      <div className="py-4">
        <h3>Talent Configurations</h3>
        <div className="col-sm-3 col-md-3 pull-right">
          <link
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
            rel="stylesheet"
          />
          {/* <div className="search-box-container search-box pull-right"> */}
            <input
              name="search"
              type="text"
              id="search"
              className="search-box-container search-box pull-right"
              placeholder="Search here...          🔍"
              onChange={(e) => setSearch(e.target.value)}
            />
          {/* </div> */}
        </div>

        <table className="table w3-hoverable"> 
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Tester Onqueue</th>
              <th>Tester Offqueue</th>
              <th>Tester Onbreak</th>
              <th>Last Modified By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredConfigs.map((item, index) => (
              <tr align="center">
                <td>{index + 1}</td>
                <td align="left">{item.talentConfigurationName}</td>
                <td>{item.onQueueTestersCount}</td>
                <td>{item.offQueueTestersCount}</td>
                <td>{item.unavailableTestersCount}</td>
                <td>{item.modifiedBy !== null ? item.modifiedBy : "-"}</td>
                <td align="left">
                  <Link
                    className="link-align view-styling"
                    to={`/ViewConfigs/${item.talentConfigurationName}`}
                  >
                    View
                  </Link>
                  <Link class="link-align edit-styling" to={``}>
                    Edit
                  </Link>
                  <Link class="link-align disable-styling" to={``}>
                    Delete
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default TalentConfiguration;
