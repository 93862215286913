import React from "react";
import { slide as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import UserName from '../Auth/UserName';
import * as FaIcons  from "react-icons/fa";
import * as FcIcons  from "react-icons/fc";
import UserAccess  from '../Auth/UserAccess';
import UserId from '../Auth/UserId';




export default props => {
  const accessLevel = UserAccess();
  const currentUser = UserId();
 
  if(accessLevel==="unknown"){
    return (
  
      <Menu className="bm-menu "{...props}> 
              <li className="nav-item "></li>              
      </Menu>
     
    );
  }
  else if(accessLevel==="Admin")
 {
  return (
    // Pass on our props

    <Menu className="bm-menu "{...props}> 
            <li className="nav-item "></li>

            <li className="nav-item menuSpacing addNewStyling textSizeHeader"> <FaIcons.FaUserCircle/> <UserName/></li>
            <li className="nav-item addNewStyling textSizeHeader"> <UserAccess/></li>

            <li className="nav-item menuSeprartor"><br></br></li>

            <li className="nav-item menuSeprartor textSizeHeader">
              <NavLink className="nav-link bm-item" exact to="/TesterProfile">
              <FaIcons.FaUsers/>    Tester Profiles
              </NavLink>
            </li>

            <li className="nav-item menuSeprartor textSizeHeader">
              <NavLink className="nav-link bm-item nav-item" exact to="/TalentConfigurations"> 
               <FaIcons.FaBookOpen/>   Talent Configurations
              </NavLink>
            </li>

            <li className="nav-item menuSeprartor textSizeHeader">
              <NavLink className="nav-link bm-item" to="/configs/add">
                <FaIcons.FaFileSignature/>    Create Talent Configuration
              </NavLink>
            </li>

            <li className="nav-item menuSeprartor textSizeHeader">
              <NavLink className="nav-link bm-item" to="/disable-configurations">
                <FaIcons.FaMicrosoft/>    Disabled Configurations
              </NavLink>
            </li>

            <li className="nav-item menuSeprartor textSizeHeader">
              <NavLink className="nav-link bm-item" exact to="/ValidBadges">
               <FaIcons.FaEdit/>    Manage Attributes
              </NavLink>
            </li>
  
            <li className="nav-item menuTopSpacing textSizeHeader"> <FaIcons.FaBug/>    Report a Bug</li>
            <li className="nav-item "></li>
            <li className="nav-item  textSizeHeader"> <FcIcons.FcAbout/>    About Us</li>
        
            
    </Menu>
   
  );
}
     else if(accessLevel==="Tester"){
    return (
      // Pass on our props
  
      <Menu className="bm-menu "{...props}> 
              <li className="nav-item "></li>
  
              <li className="nav-item menuSpacing addNewStyling textSizeHeader"> <FaIcons.FaUserCircle/> <UserName/></li>
              <li className="nav-item addNewStyling textSizeHeader greyOut"> <UserAccess/></li>
              <li className="nav-item addNewStyling   textSizeHeader"> </li>
              <li className="nav-item addNewStyling  menuSeprartor textSizeHeader"> </li>
              <li className="nav-item menuSeprartor textSizeHeader">
                <NavLink className="nav-link bm-item"  to={`/TesterView/${currentUser}`} >
                <FaIcons.FaAddressCard/>    My Profile </NavLink></li>
              <li className="nav-item menuTopSpacing textSizeHeader"> <FaIcons.FaBug/>    Report a Bug</li>
              <li className="nav-item "></li>
              <li className="nav-item  textSizeHeader"> <FcIcons.FcAbout/>    About Us</li>
          
              
      </Menu>
     
    );
  } 
  };
 