import React, { Component } from "react";
import { Modal, Button, ButtonToolbar, Text, ModalBody } from "react-bootstrap";
import axios from "axios";
import { InputGroup, InputGroupAddon } from "reactstrap";
import Select from "react-select";
import swal from "sweetalert";
import { SweetAlert } from "react-bootstrap-sweetalert";

export class MatchingLogicPopUp extends Component {
  // inputMappingChange = React.creatRef();
  constructor(props) {
    super(props);

    this.state = {
      defaultSelect: "",
      data: {},
    };
  }


  render() {
    // debugger;
    const { data } = this.props.data1;
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Configuration Mapping
          </Modal.Title>
        </Modal.Header>
        <ModalBody>
          <div className="textSizeSemiHeader">
            {this.props.data1.map((card, idx) => (
              <ul className="textSize  upperCaseFont">
                <li className="addNewStyling">{card.cardName}:</li>
                {card.options.map((lo, idx) => {
                  return (
                    <label className="radio-inline">
                      &nbsp;
                      <input
                        key={idx}
                        type="radio"
                        name={card.cardName}
                        value={lo.radioName}
                        checked={!!lo.selected}
                        onChange={this.props.onInputChange}
                      />
                    &nbsp;{lo.radioName}
                    </label>
                  );
                })}
              </ul>
            ))}
          </div>
        </ModalBody>
        <Modal.Footer>
          <Button type="submit" variant="primary" className="link-align enable-styling button-styling textSizeHeader" onClick={this.props.onSubmit}>
            Save
          </Button>
          <Button variant="danger" onClick={this.props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
