import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const TesterProfile = () => {
  const [testerProfiles, setTesterProfiles] = useState([]);

  const [search, setSearch] = useState("");

  useEffect(() => {
    loadTesterProfiles();
  }, []);

  const loadTesterProfiles = async () => {
    const result = await axios.get(
      "https://aquiline-beta.aka.corp.amazon.com/getTesterDashboardDetails"
    );
    setTesterProfiles(result.data);
    console.log("TesterProfile");
    console.log(result.data);
  };

  let filteredTps = testerProfiles.filter((profile) => {
    return profile.testerName.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <div className="container">
      <div className="py-4">
        <h3> Tester Profiles</h3>
        <div class=" textSizeHeader searchBarstyle headerMargin headerInline">
          <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
            <input name="search" type="text" id="search" className="search-box-container search-box headerInline"
              placeholder="Search here...          🔍" onChange={(e) => setSearch(e.target.value)} />  
        </div>
        <table className="table w3-hoverable">             
          <thead> 
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Alias</th>
              <th>Manger</th>
              <th>Team</th>
              <th>Status</th>
              <th>OffQueue Activity</th>
              <th>Time In Status</th>
              <th> No. of Configs</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              filteredTps.map((tester, index) => {
                return (
                  <tr align="left">
                    <td>{index + 1}</td>
                    <td>{tester.testerName}</td>
                    <td>{tester.testerName}</td>
                    <td>{tester.manager}</td>
                    <td>{tester.team}</td>
                    <td>{tester.currentStatus}</td>
                    <td align="center">{tester.reason !== null ? tester.reason : "-"}</td>
                    <td align="center">
                      {tester.currentStatusTime !== null
                        ? tester.currentStatusTime
                        : "-"}
                    </td>
                    <td align="center">
                      {tester.totalConfigsAssigned !== null
                        ? tester.totalConfigsAssigned
                        : 0}
                    </td>
                    <td align="center"> 
                      <Link
                        className="link-align view-styling"
                        to={`/ViewTester/${tester.testerName}`}
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default TesterProfile;
