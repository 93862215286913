import React, { useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

const ValidBadges = () => {
  const [badges, setBadges] = useState([]);
  const [validBadges, setValidBadges] = useState([]);
  const [test, setTest] = useState([]);

  useEffect(() => {
    loadBadges();
    loadValidBadges();
    loadTest();
  }, []);

  const loadBadges = async () => {
    const result = await axios.get("https://localhost:8443/getFilteredCopsManifest");

    setBadges(result.data.badges);
  };

  const loadTest = async () => {
    const result = await axios.get(
      "https://smes-prod-iad.iad.proxy.amazon.com/api/enrichment-configs/certification"
    );
    setTest(result.data);
    console.log("SMES", result.data);
  };

  const loadValidBadges = async () => {
    const result = await axios.get(
      "https://aquiline-beta.aka.corp.amazon.com/getAllValidBadges"
    );
    setValidBadges(result.data);
  };

  function onClick() {
    console.log("TEST", validBadges);

    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(validBadges),
    };
    console.log("Request", requestOptions);

    fetch(
      "https://aquiline-beta.aka.corp.amazon.com/updateAllValidBadges",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        return data;
      });
  }

  return (
    <>
    
      <div className="container">
        <div className="py-4">
        <div className="name"> 
        <h3 className="headerInline">Valid Badges</h3>
          <div className="submitRequest submit headerInline headerMargin textSizeHeader" onClick={onClick}>
            <h7></h7>{" "}
            <Link className="link-align enable-styling button-styling" to={``}>
              Update List
            </Link>
          </div>
          </div>
          <div><hr className="hrStylingBadges"/> </div>
          <div className=" main-wrapper">
            {badges.length &&
              badges.map((item, index) => {
                
                if (validBadges.includes(item)) {
                  return (
                    <>
                    <div className="sections textSize upperCaseFont wrapContent" key={index}>
                      <input
                        className="checkboxSpacing"
                        type="checkbox"
                        id={item}
                        onChange={(e) => {
                          let temp = [...validBadges];
                          let deletedIndex = temp.indexOf(e.target.value);
                          temp.splice(deletedIndex, 1);
                          setValidBadges([...temp]);
                        }}
                        value={item}
                        checked
                      ></input>
                      <label className="checkboxSpacing" for={item}>{item}</label>
                    </div>
                    </>
                  );
                } else {
                  return (
                    <>
                    <div className="sections textSize upperCaseFont wrapContent" key={index}>
                      <input
                        className="checkboxSpacing"
                        type="checkbox"
                        id={item}
                        value={item}
                        onChange={(e) => {
                          setValidBadges([...validBadges, e.target.value]);
                        }}
                      ></input>
                      <label className="checkboxSpacing" for={item}>{item}</label>
                    </div>
                    </>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ValidBadges);
