import React from "react";
import { withRouter } from "react-router";
import axios from "axios";
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import "./../talentConfig/ViewConfig";
import { prototype } from "react-modal";
import swal from "sweetalert";

import { AddConfigPopUp } from "./AddConfigPopUp";
import { EditPriorityPopUp } from "./EditPriorityPopUp";

const initialState = {
  defaultSelect: "",
  priority: ""
};

const config = [
  "supportedDevices",
  "activityType",
  "locales",
  "monitoringTriggerTypes",
  "badges",
  "skillTier",
  "skillTypes",
  "workflowTypes",
];

class ViewTester extends React.Component {
  constructor(props) {
    super();
    this.state = {
      data: {},
      currentTab: "vertical-tab-0",
      currentConfigData: {},
      currentTabName: "",
      currentTabDes: "",
      currentTabPriority: "",
      masterConfig: {},
      configNotInTeserProfile: [],
      addModalShow: false,
      addModalPriority: false,
      submitDisabled: true,
      textChangeValid: false,
      entitledConfigurations: {},
      priority: ""
    };
    this.onPriorityChange = this.onPriorityChange.bind(this);
  }

  componentDidMount() {
    this.getMasterConfig();
    this.getTCsNotInTesterProfile();
    const testerName = this.props.match.params.testerName;
    console.log(this.props);
    axios
      .get(`https://aquiline-beta.aka.corp.amazon.com/getTP/${testerName}`)
      .then((res) => {
        this.setState({
          data: res.data,
        });
        console.log(res.data);
        if (res.data.entitledConfigurations != null) {
          this.setConfigTab(
            0,
            Object.keys(res.data.entitledConfigurations)[0],
            Object.keys(res.data.entitledConfigurations)[0],
            Object.values(res.data.entitledConfigurations)[0]
          );
        }
      });
  }

  getMasterConfig() {
    axios
      .get(
        `https://aquiline-beta.aka.corp.amazon.com/getMasterTalentConfiguration`
      )
      .then((res) => {
        this.setState({
          masterConfig: res.data,
        });
      });
  }

  getTCsNotInTesterProfile() {
    const testerName = this.props.match.params.testerName;
    axios
      .get(
        `https://aquiline-beta.aka.corp.amazon.com/getTalentConfigurationsNotInTesterProfile/${testerName}`
      )
      .then((res) => {
        this.setState({
          configNotInTeserProfile: res.data,
        });
      });
  }

  setConfigTab = (configIndex, configName, description, priority) => {
    this.setState({
      currentTab: "vertical-tab-" + configIndex,
      currentTabName: configName,
      currentTabDes: description,
      currentTabPriority: priority,
    });

    axios
      .get(`https://aquiline-beta.aka.corp.amazon.com/getTC/${configName}`)
      .then((res) => {
        this.setState({
          currentConfigData: res.data,
        });
      });
  };

  verifyCheckbox(parent, child) {
    const { configuration } = this.state.currentConfigData || {};
    if (!configuration || !configuration[parent]) {
      return false;
    }
    return configuration[parent].find((item) => item === child);
  }

  onPriorityChange({ target }) {
    const { entitledConfigurations } = this.state.data.entitledConfigurations;
    debugger;

    let textChangeValid = target.value ? true : false;
    let submitValid = textChangeValid;
    let tempObj = Object.assign({}, this.state.data.entitledConfigurations);
    console.log("onFormSubmit : ", this.state.data);
    const nexState =
      tempObj &&
      Object.entries(tempObj).forEach(([key, value]) => {
        if (key !== target.name) return key;
        else {
          this.state.data.entitledConfigurations[target.name] = parseInt(
            target.value,
            10
          );
        }
      });
    this.setState({
      entitledConfigurations: nexState,
      submitDisabled: !submitValid,
    });
    console.log(this.state.data.entitledConfigurations);
  }

  onSubmit = (e) => {
    console.log(
      "OnSubmit Modified Data",
      this.state.data.entitledConfigurations
    );
    swal({
      title: "Are you sure?",
      text: "This will make changes in Matching Priority",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willClose) => {
      if (willClose) {
        swal("Closing the Edit Priority Dialog Box ", {
          icon: "success",
        });
        this.setState({ addModalPriority: false });
      } else {
        swal("Discard save!");
        this.setState({ addModalPriority: false });
      }
    });
  };

  onSubmitRequest = () => {
    debugger;
    console.log("Updated Config", this.state.data);

    console.log("Entitled Config ", this.state.data.entitledConfigurations);
    let requestBody = {
      userId: this.state.data.userId,
      entitledConfigurations: this.state.data.entitledConfigurations,
    };

    console.log("requestBody", requestBody);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    };
    fetch(
      "https://aquiline-beta.aka.corp.amazon.com/updateEntitledConfigs",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        return data;
      })
      .then((data) => this.setState({ apiResponse: data }));
    swal({
      text: "You have successfully Updated Priority",
      type: "success",
    }).then((okay) => {
      if (okay) {
        window.location.href = "http://localhost:3000/TesterProfile";
      }
    });
  };

  sumbitRequest(tester, tc) {
    debugger;
    const talentConfigurationName = this.props.match.params
      .talentConfigurationName;
    console.log(talentConfigurationName);
    console.log("TalentConfig" + tc);
    console.log("Tester Name", tester);
    var jsonObj = {};
    jsonObj[tc] = 99;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userId: tester,
        entitledConfigurations: jsonObj,
      }),
    };
    console.log(requestOptions);
    console.log(jsonObj);
    let res = fetch(
      "https://aquiline-beta.aka.corp.amazon.com/removeTesterFromTC",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        return data;
      })
      .then((data) => this.setState({ apiResponse: data }));
    swal({ text: "Tester Successfully Removed!!", type: "success" }).then(
      (okay) => {
        if (okay) {
          window.location.href = "http://localhost:3000/TesterProfile";
        }
      }
    );
  }

  getTabPanel = (id) => {
    debugger;
    const {
      masterConfig,
      data,
      addModalPriority,
      addModalClose,
      entitledConfigurations,
    } = this.state;

    let test = data.entitledConfigurations;
    console.log("Entitled Configuration in POPUP", entitledConfigurations);
    let addModalPriorityClose = () =>
      this.setState({ addModalPriority: false });
    return (
      <div>
        <hr className="hrStyling" />
        <div className="name">
          <h9 className="headerInline  textSizeMegaHeader">
            {this.props.match.params.testerName}/Tester Profile
          </h9>
          <div className="button headerInline headerMargin textSizeHeader">
            <button
              className="link-align disable-styling button-styling"
              onClick={(event) =>
                (window.location.href = "http://localhost:3000/TesterProfile")
              }
            >
              {" "}
              Discard
            </button>
            <button
              className="link-align enable-styling button-styling"
              onClick={this.onSubmitRequest}
            >
              Save
            </button>
          </div>
        </div>
        <div className="container">
          <div className="py-4">
            <TabPanel tabId={id}>
              <br />
              <br />
              <br />
              <div>
                <h9 className="headerInline addNewStyling textSizeHeader">
                  {this.state.currentTabName}
                </h9>
                <h11 className="headerInline addNewStyling headerMargin textSizeHeader">
                  Priority - {this.state.currentTabPriority}
                  <span> </span>
                  <button
                    className="link-align edit-styling button-styling textSize"
                    onClick={() => this.setState({ addModalPriority: true })}
                  >
                    &nbsp;&nbsp; Edit
                  </button>
                  <EditPriorityPopUp
                    show={this.state.addModalPriority}
                    onHide={addModalPriorityClose}
                    data={this.state.data.entitledConfigurations}
                    onPriorityChange={this.onPriorityChange}
                    onSubmit={this.onSubmit}
                    submitDisabled={this.state.submitDisabled}
                  />
                </h11>
              </div>
           
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text textSize addNewStyling">
                    Description:
                  </span>
                </div>
                <textarea
                  class="form-control textSize"
                  aria-label="With textarea"
                  value={this.state.currentConfigData.description}
                ></textarea>

                {/* <EditPriorityPopUp
                show={this.state.addModalPriority}
                onHide={addModalClose}
              /> */}
              </div>

              <div className="viewConfigs">
                {masterConfig.configuration &&
                  config.map((item, index) => {
                    return (
                      <>
                        <h8 className="addNewStyling textSizeSemiHeader headerInline">
                          {item}
                        </h8>
                        <div className="main-wrapper" key={index}>
                          {masterConfig.configuration[item].map(
                            (childItem, childIndex) => {
                              return (
                                <div
                                  className="sections textSize upperCaseFont wrapContent"
                                  key={childIndex}
                                >
                                  <div>
                                    {" "}
                                    <input
                                      type="checkbox"
                                      className="checkboxSpacing"
                                      id={childItem}
                                      value={childItem}
                                      checked={this.verifyCheckbox(
                                        item,
                                        childItem
                                      )}
                                    />
                                    <label
                                      className="checkboxSpacing"
                                      for={childItem}
                                    >
                                      {childItem}
                                    </label>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </>
                    );
                  })}
              </div>
            </TabPanel>
          </div>
        </div>
      </div>
    );
  };

  render() {
    debugger
    const {
      configNotInTeserProfile,
      data,
      addModalShow,
      addModalPriority,
    } = this.state;
    const prevState = this.state.data.entitledConfigurations
    console.log("Previous state", prevState)
    const testerName = this.props.match.params.testerName;
    console.log("DATA" + this.state.data);
    let addModalClose = () => this.setState({ addModalShow: false });

    let addModalPriorityClose = () =>
      this.setState({ addModalPriority: false });
    return (
      // <div className="container">
      <div className="py-2">
        <Tabs
          defaultTab={this.state.currentTab}
          vertical
          className="vertical-tabs"
        >
          <TabList>
            {this.state.data.entitledConfigurations &&
              Object.keys(this.state.data.entitledConfigurations).map(
                (config, index) => {
                  return (
                    <Tab
                      className="textSize "
                      tabFor={"vertical-tab-" + index}
                      key={index}
                      onClick={() =>
                        this.setConfigTab(
                          index,
                          config,
                          this.state.currentConfigData.description,
                          Object.values(this.state.data.entitledConfigurations)[
                          index
                          ]
                        )
                      }
                    >
                      <div>
                        <label className="headerInline wrapContent">
                          {config}&nbsp;
                        </label>
                        <button
                          className=" close closeTab  headerMargin headerInline"
                          type="button"
                          onClick={() =>
                            this.sumbitRequest(
                              this.props.match.params.testerName,
                              config
                            )
                          }
                        >
                          ×
                        </button>
                      </div>
                    </Tab>
                  );
                }
              )}
            <label
              tabFor="horizental-tab-one"
              className="textSize addNewStyling centerAlign"
              onClick={() => this.setState({ addModalShow: true })}
            >
              + Add New{" "}
            </label>

            <AddConfigPopUp
              show={this.state.addModalShow}
              onHide={addModalClose}
              data={testerName}
            />
          </TabList>

          {this.getTabPanel(this.state.currentTab)}
        </Tabs>
      </div>
      // </div>
    );
  }
}
export default withRouter(ViewTester);
