import React, { useEffect, useState } from 'react';
import Navbar from "./components/layout/Navbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import ViewConfigs from "./components/talentConfig/ViewConfig";
import AddConfigs from "./components/talentConfig/AddConfigs";
import TalentConfiguration from "./components/pages/TalentConfiguration";
import TesterProfile from "./components/pages/TesterProfile";
import ViewTester from "./components/testerProfile/ViewTester";
import "./style.css";
import ValidBadges from "./components/pages/ValidBadges";
import DisableConfigs from "./components/pages/DisableConfigs";
import "./SidebarStyles.css";
import Sidebar from "./components/layout/Sidebar";
import Homepage from "./components/pages/Home";
import Amplify, { Auth, Hub } from 'aws-amplify';
import TesterView from "./components/testerProfile/TesterView";


Amplify.configure({
  Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'us-east-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      // userPoolId: 'us-east-1_Q8d1lhsxp', //NASA
      userPoolId: 'us-east-1_xxWUK1Sor', //CopsHome
      // userPoolId: 'us-east-1_iRXHZW9cK', //Aquiline
      

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      // userPoolWebClientId: '5bsdlb9qbqcj6ogptv13c11ak5', //NASA-Aq
      // userPoolWebClientId: '7coqm9rnla4adqus6uvqn5e4om', //NASA-Dash
      userPoolWebClientId: '4s8eb0qsnrdolnq6ofsugr05t', //CopsHome_NoSecret
      // userPoolWebClientId: 'rlf3140rlumcmtpvc3nsu1jkc', //Aquiline_AppClient
      // userPoolWebClientId: 'ung6rud95p32m4esjhps1p4bi', //Aquiline_NoSecret


       // OPTIONAL - Hosted UI configuration
      oauth: {
          // domain: 'nasadash.auth.us-east-1.amazoncognito.com', //NASA
          domain: 'copshomefederate.auth.us-east-1.amazoncognito.com', //CopsHome
          // domain: 'aquilinefederate.auth.us-east-1.amazoncognito.com', //Aquiline
          scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
          // redirectSignIn: 'http://localhost:3000/',
          // redirectSignIn: 'https://beta.aquiline.cops.alexa.a2z.com',
          redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
          // redirectSignIn: 'https://aquiline-react-static.s3.amazonaws.com/index.html',
          redirectSignOut: 'https://aquiline-react-static.s3.amazonaws.com/login/oauth2/code/cognito',
          responseType: 'code' // REFRESH token will only be generated when the responseType is code
      }
  }
});

function App() {

  const [user, setUser] = useState(null);
  const currentUser = null;
  let [userData, setUserData] = useState(null);

  useEffect(() => {
    getUser().then(userData => setUserData(userData));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      // .then(userData => userData)
      .then(userData => console.log("userinfoAppJs", userData))
      .catch(() => Auth.federatedSignIn());
  }

  return (
    <div className="App">
      <Router>
        <div className="App">
          <Navbar />
          {/* <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"}/> */}
          <Switch>
          <Route exact path="/" component={Homepage}/>
           <Route exact path="/Home" component={Homepage}/>
            <Route exact path="/TalentConfigurations" component={TalentConfiguration}/>
            <Route exact path="/TesterProfile" component={TesterProfile} />
            <Route exact path="/ValidBadges" component={ValidBadges} />
            <Route exact path="/configs/add" component={AddConfigs} />
            <Route exact path="/disable-configurations" component={DisableConfigs}/>
            <Route exact path="/ViewConfigs/:talentConfigurationName" component={ViewConfigs}/>
            <Route exact path="/ViewTester/:testerName" component={ViewTester}/>
            <Route exact path="/TesterView/:testerName" component={TesterView}/>
            </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;