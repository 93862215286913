import UserAttributes from './UserAttributes';



const UserAccess = () => {

   

    const userAttributes = UserAttributes();
    var access = "unknown"; 

    if(userAttributes!=null){
      const ldapGroups = userAttributes['custom:groups'];
      console.log("PrintingUserAccess_LDAPGroups", ldapGroups);
      if(ldapGroups.includes('Aquiline-Admin')){
        access = "Admin";
        console.log("PrintingUserAccess_", access);
      } else if(ldapGroups.includes('Aquiline-WFM')){
        access = "WFM";
        console.log("PrintingUserAccess_", access);
      } else if(ldapGroups.includes('nasausers-alexacops')){
        access = "Tester";
        console.log("PrintingUserAccess_", access);
      }
    }
    return access;
}

 export default UserAccess;