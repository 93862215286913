import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";

const DisableConfigs = () => {
  const [disableConfigs, setDisableConfigs] = useState([]);

  useEffect(() => {
    loadDisableConfigs();
  }, []);

  const loadDisableConfigs = async () => {
    const result = await axios.get(
      "https://aquiline-beta.aka.corp.amazon.com/getDisabledTalentConfigurationDashboardDetails"
    );
    setDisableConfigs(result.data);
  };

  const [search, setSearch] = useState("");
  const [filteredDisabledConfigs, setFilteredDisabledConfigs] = useState([]);

  function onClick(item) {
    console.log("ConfogName: " + item);
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        configId: item,
        isEnabled: true,
      }),
    };
    console.log("Request", requestOptions);

    fetch("https://aquiline-beta.aka.corp.amazon.com/enableTC", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        return data;
      });
    swal({
      text: "Configuration Enabled",
      icon: "success",
    }).then((okay) => {
      if (okay) {
        window.location.href = "http://localhost:3000/disable-configurations";
      }
    });
  }

  useEffect(() => {
    setFilteredDisabledConfigs(
      disableConfigs.filter((config) =>
        config.talentConfigurationName
          .toLowerCase()
          .includes(search.toLowerCase())
      )
    );
  }, [search, disableConfigs]);

  return (
    <div className="container">
      <div className="py-4">
        <h3>Disabled Configurations</h3>
        <div class="col-sm-3 col-md-3 pull-right">
          <link
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
            rel="stylesheet"
          />
          {/* <div className="search-box-container search-box"> */}
          <input
            name="search"
            type="text"
            id="search"
            className="search-box-container search-box pull-right"
            placeholder="Search here...          🔍"
            onChange={(e) => setSearch(e.target.value)}
          />
          {/* </div> */}
        </div>

        <table className="table w3-hoverable">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Tester Onqueue</th>
              <th>Tester Offqueue</th>
              <th>Tester Onbreak</th>
              <th>Last Modified By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredDisabledConfigs.map((item, index) => (
              <tr align="center">
                <td>{index + 1}</td>
                <td align="left">{item.talentConfigurationName}</td>
                <td>{item.onQueueTestersCount}</td>
                <td>{item.offQueueTestersCount}</td>
                <td>{item.unavailableTestersCount}</td>
                <td>{item.modifiedBy !== null ? item.modifiedBy : "-"}</td>
                <td align="left"> 
                  <button className="link-align enable-styling button-styling" onClick={() => onClick(item.talentConfigurationName)}>
                    Enable </button>
                  <button className="link-align disable-styling button-styling " to={``}>
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default DisableConfigs;
