import User from './User';



const UserId = () => {

  const user = User();
  var userId = "notLoggedInDa";

  if(user != null){
      userId = user.username;
      userId = userId.replace("@amazon.com", "");
      userId = userId.replace("copshomeusers_", "");
  }
  console.log("userinfoName: " , userId)
  return (userId)
}

export default UserId;