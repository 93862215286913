import React, { Component } from "react";
import { Modal, Button, ButtonToolbar } from "react-bootstrap";
import axios from "axios";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import Select from "react-select";
import swal from "sweetalert";
import { prototype } from "react-modal";

export class AddConfigPopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      configNotInTeserProfile: [],
      defaultSelect: "",
      priority: "",
      configError: "",
      priorityError: ""
    };
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    console.log("POP UP CONFIG NAME " + this.props.data);
    axios
      .get(
        `https://aquiline-beta.aka.corp.amazon.com/getTalentConfigurationsNotInTesterProfile/${this.props.data}`
      )
      .then((res) => {
        const options = this.getFormatedData(res.data);
        console.log(options);
        this.setState({
          configNotInTeserProfile: options
        });
        console.log(res.data);
      });
  }

  getFormatedData = (options) => {
    let result = [{ label: "", value: "" }];

    for (let i = 0; i < options.length; i++) {
      result.push({
        label: options[i],
        value: options[i],
      });
    }
    return result;
  };

  handleChange = (selectedOption) => {
    this.setState({ defaultSelect: selectedOption }, () => {
      console.log("Selected Config", this.state);
    });
  };

  handlePriorityChange = (event) => {
    this.setState({ priority: event.target.value }, () => {
      console.log("Given Priority ", this.state);
    });
  };


  handleValidation() {
    let priority = this.state.priority;
    let defaultSelect = this.state.defaultSelect;
    let errors = {};
    let formIsValid = true;

    if (!priority) {
      formIsValid = false
      errors["priority"] = "Cannot be empty";
    }

    if (!defaultSelect) {
      formIsValid = false
      errors["defaultSelect"] = "Cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  validate = () => {
    let configError = "";
    let priorityError = "";

    if (this.state.defaultSelect == "") {
      configError = "Please select Config"
    }

    if (this.state.priority == "") {
      priorityError = "Priority can not be blank"
    }
    if (configError || priorityError) {
      this.setState({ configError, priorityError });
      return false;
    }
    return true
  }

  handleSubmit = () => {
    // if (this.handleValidation()) {
    const isValid = this.validate();
    if (isValid) {

      let requestBody = {
        //   userId: this.state.defaultSelect ? this.state.defaultSelect.value : "",
        userId: this.props.data,
        entitledConfigurations: {
          [this.state.defaultSelect.value]: this.state.priority,
        },
      };

      console.log(requestBody);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      };
      console.log(requestOptions);

      fetch(
        "https://aquiline-beta.aka.corp.amazon.com/addTesterToTC",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          return data;
        })
        .then((data) => this.setState({ apiResponse: data }));
      swal({
        text: "You have successfully added Configuration to Tester Profile",
        type: "success",
      }).then((okay) => {
        if (okay) {
          window.location.href = "http://localhost:3000/TesterProfile";
        }
      });
    }
    // } else {
    //   alert("Form has errors.")
    // }
    // let requestBody = {
    //   //   userId: this.state.defaultSelect ? this.state.defaultSelect.value : "",
    //   userId: this.props.data,
    //   entitledConfigurations: {
    //     [this.state.defaultSelect.value]: this.state.priority,
    //   },
    // };

    // console.log(requestBody);

    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(requestBody),
    // };
    // console.log(requestOptions);

    // fetch(
    //   "https://aquiline-beta.aka.corp.amazon.com/addTesterToTC",
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log(data);
    //     return data;
    //   })
    //   .then((data) => this.setState({ apiResponse: data }));
    // swal({
    //   text: "You have successfully added Configuration to Tester Profile",
    //   type: "success",
    // }).then((okay) => {
    //   if (okay) {
    //     window.location.href = "http://localhost:3000/TesterProfile";
    //   }
    // });
  };

  setValue = value => {
    this.props.setState(prevState => ({
      defaultSelect: {
        ...prevState.defaultSelect.value,
        value
      }
    }));
  };
  onHide = () => {
    debugger
    this.setState({ defaultSelect: "", priorityError: "", configError: "" }, () => {
      this.props.onHide()
    });
  };


  render() {
    const { configNotInTeserProfile, defaultSelect } = this.state;
    console.log("TesterName " + this.props.data);
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={this.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="textSizeHeader addNewStyling">
            Add Configuration
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="textSizeSemiHeader addNewStyling">
            <br></br>
            Add Configuration To Tester Profile
            <Select
              ref="defaultSelect"
              class="browser-default custom-select"
              id="Config"
              name="Config"
              value={defaultSelect}
              // defaultValue={defaultValue}
              onChange={this.handleChange}
              options={configNotInTeserProfile}
            ></Select>
            <div style={{ color: "red" }}>{this.state.configError}</div>
            <br></br>''
            Select priority for the configuration:
              <Input
              ref="priority"
              id="Priority"
              name="Priority"
              placeholder="1-99"
              className="textSizeSemiHeader addNewStyling"
              min={1}
              max={99}
              type="number"
              step="1"
              onChange={this.handlePriorityChange}
            />
            <div style={{ color: "red" }}>{this.state.priorityError}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary" className="link-align enable-styling  button-styling textSizeHeader" onClick={this.handleSubmit}>
            Save
          </Button>
          <Button variant="danger" className="link-align disable-styling button-styling  textSizeHeader" onClick={this.onHide}>
            Discard
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
