import React from "react";
import UserFullName from '../Auth/UserFullName';
import { Auth } from 'aws-amplify';
import Sidebar from './Sidebar';

const Navbar = () => {
  return (
  
    <nav className="navbar navbar-expand-lg ">
      <div name="container">
 
        <div class="collapse navbar-collapse" id="main_navbar">
        <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"}/> 
        <ul class="nav navbar-nav ml-auto"></ul> 
            <ul class="nav navbar-nav">
                <li class="nav-item">
        <a className="navbar-brand aquiline-logo" href="/">  <img className="navbar-icon" src={require("./index.png")}/>
          Aquiline</a>
          </li>
            </ul>
            <ul class="nav navbar-nav mr-auto"></ul> 
            <ul class="nav navbar-nav">
                <li class="nav-item">
                <span className="navbar-user"><UserFullName/></span>
                </li>
            </ul>
       </div>
        {/* <span className="username-logo "><Profile/></span> */}
        {/* <span><Logo/></span> */}
        
      </div>
    </nav>
   
  ); 
};

export default Navbar;