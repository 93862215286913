import React from "react";
import { withRouter } from "react-router";
import axios from "axios";
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import swal from "sweetalert";

import { AddTesterPopUp } from "./AddTesterPopUp";
import { MatchingLogicPopUp } from "./MatchingLogicPopUp";

const config = [
  "supportedDevices",
  "activityType",
  "locales",
  "monitoringTriggerTypes",
  "badges",
  "skillTier",
  "skillTypes",
  "workflowTypes",
];

const defaultMapping = [
  {
    cardName: "badges",
    options: [{ radioName: "Subset" }, { radioName: "Exact" }],
  },
  {
    cardName: "skillTypes",
    options: [{ radioName: "Subset" }, { radioName: "Exact" }],
  },
  {
    cardName: "devices",
    options: [{ radioName: "Subset" }, { radioName: "Exact" }],
  },
]

class ViewConfigs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      talentConfig: {},
      testerList: [],
      testerNotInConfig: [],
      selectedOptionId: 0,
      addModalShow: false,
      addModalShowMatch: false,
      configName: "",
      status: true,
      configurationMapping: {},
      data1: [
        {
          cardName: "badges",
          options: [{ radioName: "Subset" }, { radioName: "Exact" }],
        },
        {
          cardName: "skillTypes",
          options: [{ radioName: "Subset" }, { radioName: "Exact" }],
        },
        {
          cardName: "devices",
          options: [{ radioName: "Subset" }, { radioName: "Exact" }],
        },
      ],
    };
  }

  //Trying using props data
  onSubmit = (e) => {
    e.preventDefault();
    // this.onMappingChange(this.state.data1);
    console.log("OnSubmit Modified Data", this.state.data1);
    swal({
      title: "Are you sure?",
      text: "This will make changes in Matching Configuration",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willClose) => {
      if (willClose) {
        swal("Closing the Mapping Configuration ", {
          icon: "success",
        });
        this.setState({ addModalShowMatch: false });
      } else {
        swal("Discard save!");
        this.setState({ addModalShowMatch: false });
      }
    });
  };

  onInputChange = ({ target }) => {
    const { data1 } = this.state;
    const nexState = data1.map((card) => {
      if (card.cardName !== target.name) return card;
      return {
        ...card,
        options: card.options.map((opt) => {
          const checked = opt.radioName === target.value;
          return {
            ...opt,
            selected: checked,
          };
        }),
      };
    });
    this.setState({ data1: nexState });
    console.log("Data1 in ONChange", this.state.data1);
  };

  componentDidMount() {
    axios
      .get(
        `https://aquiline-beta.aka.corp.amazon.com/getMasterTalentConfiguration`
      )
      .then((res) => {
        this.setState({
          talentConfig: res.data,
        });
        this.getTalentConfigByConfigId();
        this.getTesterListByConfigId();
        this.getTesterNotInList();
      });
  }

  getTalentConfigByConfigId() {
    const talentConfigurationName = this.props.match.params.talentConfigurationName;
    axios
      .get(
        `https://aquiline-beta.aka.corp.amazon.com/getTC/${talentConfigurationName}`
      )
      .then((res) => {
        if (!res.data.configurationMapping) {
          this.setState({
            data1: [...defaultMapping],
            data: res.data,
            prevData: [...defaultMapping]
          })
        } else {
          const newData = [...this.state.data1];
          newData && newData.forEach((item) => {
            const cardName = item.cardName;
            item.options.map(subItem => {
              if (!res.data.configurationMapping[cardName] &&
                subItem.radioName === "Subset"
              ) {
                subItem.selected = true;
              } else if (
                res.data.configurationMapping[cardName] &&
                subItem.radioName === "Exact"
              ) {
                subItem.selected = true;
              }
            })
          })
          this.setState({
            data1: newData,
            data: res.data,
            prevData: newData
          });

        }
      });
  }

  getTesterListByConfigId() {
    const talentConfigurationName = this.props.match.params
      .talentConfigurationName;
    axios
      .get(
        `https://aquiline-beta.aka.corp.amazon.com/getTestersStatusInTC/${talentConfigurationName}`
      )
      .then((res) => {
        this.setState({
          testerList: res.data,
        });

        // console.log("Tester List" + res.data);
      });
  }

  getTesterNotInList() {
    const talentConfigurationName = this.props.match.params
      .talentConfigurationName;
    axios
      .get(
        `https://aquiline-beta.aka.corp.amazon.com/getTestersNotInTalentConfiguration/${talentConfigurationName}`
      )
      .then((res) => {
        this.setState({
          testerNotInConfig: res.data,
        });
        console.log(res.data);
      });
  }

  verifyCheckbox(parent, child) {
    const { configuration } = this.state.data || {};
    if (!configuration || !configuration[parent]) {
      return false;
    }
    return configuration[parent].find((item) => item === child);
  }

  handleClick() {
    this.setState({ clicked: !this.state.clicked });
  }

  handleChange = (event) => {
    this.setState(
      {
        status: event.target.checked,
      }
      // () => console.log(this.state.status)
    );
  };

  sumbitRequest(tester, tc) {
    debugger;
    const talentConfigurationName = this.props.match.params
      .talentConfigurationName;
    console.log(talentConfigurationName);
    console.log("TalentConfig" + tc);
    console.log("Tester Name", tester);
    var jsonObj = {};
    jsonObj[tc] = 99;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userId: tester,
        entitledConfigurations: jsonObj,
      }),
    };
    console.log(requestOptions);
    console.log(jsonObj);
    let res = fetch(
      "https://aquiline-beta.aka.corp.amazon.com/removeTesterFromTC",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        return data;
      })
      .then((data) => this.setState({ apiResponse: data }));
    swal({ text: "Tester Successfully Removed!!", type: "success" }).then(
      (okay) => {
        if (okay) {
          window.location.href = "http://localhost:3000/TalentConfigurations";
        }
      }
    );
  }

  handleConfig = (isChecked, parent, child) => {
    const { configuration } = this.state.data;
    console.log("Before ", configuration);
    let temp = configuration;
    console.log("Assigned Config", temp);
    if (isChecked) {
      if (!temp[parent]) temp[parent] = [];
      temp[parent].push(child);
      this.setState({ configuration: temp });
    } else {
      if (!temp[parent]) temp[parent] = [];
      temp[parent].pop(child);
      if (temp[parent].pop() == undefined) {
        temp[parent] = null;
      }
      this.setState({ configuration: temp });
    }
    this.setState({ configuration: temp });
    console.log(configuration);
  };

  //Based on radioName and selected value, forming
  getFormatedMapping = (data) => {
    // debugger;
    let arrayObj = [];
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < 2; j++) {
        if (data[i].options[j].selected) {
          if (data[i].options[j].radioName === "Exact") {
            arrayObj.push({
              [data[i].cardName]: 1,
            });
          } else {
            arrayObj.push({
              [data[i].cardName]: 0,
            });
          }
        }
      }
    }

    var object = Object.assign({}, ...arrayObj);

    console.log(object);
    return object;
  };

  onSubmitRequest = () => {
    // debugger;
    const mapping = this.getFormatedMapping(this.state.data1);
    // this.setState({ data1: mapping });
    console.log("Updated Config inside Save", this.state.data.configuration);
    console.log("Updated Status", this.state.status);
    console.log("Updated Mapping", this.state.data1);
    let requestBody = {
      configId: this.state.data.configId,
      version: this.state.data.version,
      history: this.state.data.history,
      description: this.state.data.description,
      isEnabled: this.state.status,
      configuration: this.state.data.configuration,
      configurationMapping: mapping,
    };
    console.log(requestBody);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    };
    fetch("https://aquiline-beta.aka.corp.amazon.com/updateTC", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        return data;
      })
      .then((data) => this.setState({ apiResponse: data }));
    swal({
      text: "You have successfully Updated Configuration",
      type: "success",
    }).then((okay) => {
      if (okay) {
        window.location.href = "http://localhost:3000/TalentConfigurations";
      }
    });
  };

  render() {
    const {
      talentConfig,
      testerList,
      testerNotInConfig,
      addModalShow,
      addModalShowMatch,
      data,
      data1,
    } = this.state;
    console.log("Updated  Data1", data1);
    console.log("Updated API Data ", data);
    const prevState = data1
    let addModalClose = () => {
      this.setState({ addModalShow: false, addModalShowMatch: false, data1: [...this.state.prevData], })
    };
    let configurationName = () =>
      this.setState({ configName: this.props.match.params });
    const talentConfigurationName = this.props.match.params
      .talentConfigurationName;

    return (
      <div className="container">
        <div className="py-4">
          <Tabs>
            <TabList>
              <div className="name">
                <h9 className="headerInline addNewStyling textSizeHeader">{talentConfigurationName.toUpperCase()}</h9>
                <div className="button headerInline headerMargin textSizeHeader">
                  <button
                    className="link-align disable-styling button-styling"
                    onClick={(event) =>
                      (window.location.href =
                        "http://localhost:3000/TalentConfigurations")
                    }
                  >
                    Discard
                  </button>
                  <button
                    className="link-align enable-styling button-styling"
                    onClick={this.onSubmitRequest}
                  >
                    Save
                  </button>
                </div>
              </div>

              <Tab tabFor="horizental-tab-one" className="addNewStyling textSizeHeader">Configuration</Tab>

              <Tab tabFor="horizental-tab-two" className="addNewStyling textSizeHeader">Testers</Tab>
            </TabList>
            <br></br>
            <TabPanel tabId="horizental-tab-one">
              <div className="custom-control custom-switch right">
                <div>
                  <input
                    type="checkbox"
                    checked={this.state.status}
                    value={this.state.status}
                    onChange={this.handleChange}
                    className="custom-control-input headerInline"
                    id="customSwitch1"
                  />
                  <label className="custom-control-label addNewStyling textSizeHeader headerInline" for="customSwitch1" >
                    {this.state.status ? 'Enabled' : 'Disabled'}
                  </label>
                  <span className="textSizeHeader">&nbsp;&nbsp;|</span>&nbsp;&nbsp;
                <button
                    className="link-align addNewStyling button-styling textSizeHeader headerMargin"
                    onClick={() => this.setState({ addModalShowMatch: true })}
                  > Mapping Logic
                </button>
                </div>
                  <MatchingLogicPopUp
                    data1={data1}
                    show={this.state.addModalShowMatch}
                    onHide={addModalClose}
                    mappingAdded={this.onMappingChange}
                    onSubmit={this.onSubmit}
                    onInputChange={this.onInputChange}
                  />
                  <p>{this.state.ChangeConfigurationMapping}</p>
                {/* <p>{this.state.ChangeConfigurationMapping}</p> */}
              </div>

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text textSize addNewStyling">Description:</span>
                </div>
                <textarea class="form-control textSize" aria-label="With textarea" value={data.description}></textarea>             
              </div>
      

              <div className="viewConfigs">
                {talentConfig.configuration &&
                  config.map((item, index) => {
                    return (
                      <>
                        <h8 className="addNewStyling textSizeSemiHeader">{item}</h8>
                        <div className="main-wrapper" key={index}>
                          {talentConfig.configuration[item].map(
                            (childItem, childIndex) => {
                              return (
                                <div className="sections textSize upperCaseFont wrapContent" key={childIndex}>
                                  <input
                                  className="checkboxSpacing"
                                    type="checkbox"
                                    onChange={(event) => {
                                      this.handleConfig(
                                        event.target.checked,
                                        item,
                                        childItem
                                      );
                                    }}
                                    id={childItem}
                                    value={childItem}
                                    checked={this.verifyCheckbox(
                                      item,
                                      childItem
                                    )}
                                  ></input>
                                  <label className="checkboxSpacing" for={childItem}>{childItem}</label>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </>
                    );
                  })}
              </div>
            </TabPanel>
            <TabPanel tabId="horizental-tab-two">
              {/* <ButtonToolbar className="float-right"> */}
              <button className="link-align addNewStyling button-styling headerMargin textSizeSemiHeader"  
              onClick={() => this.setState({ addModalShow: true })} > 
                Add Tester
              </button>

              <AddTesterPopUp
                show={this.state.addModalShow}
                onHide={addModalClose}
                data={talentConfigurationName}
              />

              <table class="table w3-hoverable">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Alias</th>
                    <th>Status</th>
                    <th>Reason</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {testerList && testerList.length ? (
                    testerList.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.testerName}</td>
                        <td>{item.testerName}</td>
                        <td>{item.currentStatus}</td>
                        <td>{item.reason !== null ? item.reason : "-"}</td>
                        <td>
                          <button className="link-align remove-styling button-styling" onClick={() => this.sumbitRequest(item.testerName, talentConfigurationName)
                          }>Remove</button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <th scope="row">{1}</th>
                      <td>
                        No Testers assigned with talent Configuration named{" "}
                        <b> {talentConfigurationName}</b>{" "}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

            </TabPanel>
          </Tabs>
        </div>
      </div>
    );
  }
}
export default withRouter(ViewConfigs);
