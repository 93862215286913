import UserAttributes from './UserAttributes';


const UserName = () => {

  const userAttributes = UserAttributes();
  var userId = "notLoggedInDa";

  if(userAttributes != null){
      userId = userAttributes['given_name'];
      console.log("PrintingUserName: " , userId)
      return (userId)
  }else{
    console.log("PrintingUserName: null")
    return (userId)
  }
  

}

  export default UserName;
